
import { Link } from 'react-router-dom'

export default function NotFound() {

  return (
    <div className="wrapper">



      <div className="header-container">
        <div className="header">
          <span>4</span>
          <span>0</span>
          <span>4</span>
        </div>
      </div>

      <div className="notFoundFooter">
        <span>Seems you are lost, come back <Link to='/'><u>Home</u></Link></span>
        <div className="footer-wrapper"></div>
      </div>

      </div>
  )
}
